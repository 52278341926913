<template>
  <div class="va-page-not-found justify--center pb-6">
    <div class="va-page-not-found__inner align--center">
      <slot name="image"/>
      <div class="va-page-not-found__text px-4 text--center">
        <span>
          {{$t('404.text')}}
        </span>
      </div>
      <slot/>
      <va-button outline :style="{ color: 'white', borderColor: 'white' }" :to="{ name: getHomePage() }">{{$t('404.back_button')}}</va-button>
    </div>
  </div>
</template>

<script>
import { getDefaultRoleHomePage } from '@/store/modules/auth/utility'

export default {
  name: 'at-page-not-found',
  methods: {
    getHomePage () {
      return getDefaultRoleHomePage(this.$store.getters.get_user_role).name
    },
  },
}
</script>

<style lang="scss">
.va-page-not-found {
  background-image: url('~@/assets/imgs/Background_page_404.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  padding-top: 10%;
  position: relative;

  @include media-breakpoint-down(sm) {
    padding-top: 8%;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    padding-bottom: 4rem;
  }

  &__title {
    font-size: 3rem;
    line-height: 1.25em;
    font-weight: 500;

    @include media-breakpoint-down(xs) {
      font-size: 1.5rem;
    }
  }

  &__text {
    margin-bottom: 0.5rem;
    color: #ffffff;
  }
}
</style>
